<template>
  <v-container>
    <v-form ref="form">
    <v-row>
      <v-col cols="12" md="8" sm="6" class="d-flex justify-start align-center">
        <v-skeleton-loader
          v-if="!badge || !badge.image || !badge.image.base64Image"
          class="ma-2"
          style="height: 90px; width: 90px;"
          type="image"
        ></v-skeleton-loader>
        <transition name="fade-transition" mode="out-in">
          <v-tooltip bottom v-if="badge && badge.image && badge.image.base64Image && editModeEnabled" key="edit-image-enabled">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                offset-x="10"
                offset-y="20"
                color="primary"
                icon="mdi-pencil"
                overlap
              >
                <v-img
                  class="image-select mb-1"
                  @click="showUpdateImageDialog"
                  max-height="100"
                  max-width="100"
                  min-height="100"
                  min-width="100"
                  :src="badge.image.base64Image"
                  contain
                  style="width: 100px"
                  v-bind="attrs"
                  v-on="on"
                />
              </v-badge>
              </template>
            <span>Click to edit</span>
          </v-tooltip>
          <v-img
            key="edit-image"
            @click="showBigImageDialog = true"
            v-if="badge && badge.image && badge.image.base64Image && !editModeEnabled"
            class="mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            :src="badge.image.base64Image"
            contain
            style="width: 100px"
          />
        </transition>
        <v-skeleton-loader
          v-if="!badge"
          type="list-item-two-line"
          class="badge-name-loading"
          style="width: 500px"
        ></v-skeleton-loader>
        <transition name="slide-y-transition" mode="out-in">
        <span v-if="badge && !editModeEnabled" class="ma-2 text-h4" key="edit-display-off">{{ badge.displayName }}</span>
          <v-text-field
            key="edit-displayName"
            solo
            style="margin-top:25px;"
            v-if="badge && editModeEnabled"
            class="ml-2"
            v-model="badge.displayName"
            label="Name"
            hint="The name field is provided with the badge"
            persistent-hint
          />
        </transition>
      </v-col>
      <v-spacer/>
      <transition name="slide-y-transition" mode="out-in">
        <v-col cols="12" md="2" class="d-flex justify-center align-center" v-if="!editModeEnabled" key="edit-mode-disabled">
          <v-btn class="ma-1" color="primary" @click="editModeEnabled = true" v-if="hasAdminRole">Edit Badge</v-btn>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="editModeEnabled" key="edit-mode-enabled">
          <div>
          <v-btn class="ma-1" color="error" :loading="isDeleting" :disabled="isDeleting" @click="displayDeleteDialog" style="width: 130px">Delete</v-btn>
          <br/>
          <v-btn class="ma-1" :loading="isSubmitted" :disabled="isSubmitted" @click="updateBadge" style="width: 130px">Save</v-btn>
          </div>
        </v-col>
      </transition>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="12" xl="12">
      <v-tabs
        v-model="tabView"
      >
        <v-tab>Badge Details</v-tab>
        <v-tab>Employees</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabView">
        <v-tab-item class="no-transition">
          <div class="tab-item-wrapper">
          <v-row class="edit-component" v-if="badge">
            <v-col cols="12" md="12">
              <v-card flat>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <span class="text-h6 pa-2">Tags:</span>
                        <transition name="slide-y-transition" mode="out-in">
                          <div v-if="!editModeEnabled" key="edit-chip-disabled" class="ml-3">
                            <v-chip
                              v-for="(chip, index) in badge.tags"
                              :key="index"
                              class="ma-2"
                            >
                              {{ chip }}
                            </v-chip>
                          </div>
                          <div v-else key="edit-chip" class="ml-3">
                            <v-combobox
                              style="max-width: 500px"
                              solo
                              v-model="badge.tags"
                              hint="Type a tag and press enter. Maximum of 5."
                              :rules="tagRules"
                              label="Tags"
                              multiple
                              persistent-hint
                              small-chips
                            >
                            </v-combobox>
                          </div>
                        </transition>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <span class="text-h6 pa-2">Description:</span>
                        <transition name="slide-y-transition" mode="out-in">
                          <v-card flat v-if="!editModeEnabled" key="edit-description" class="ml-3">
                            <v-card-text>
                              <div v-html="compiledMarkdown(badge.description)"></div>
                            </v-card-text>
                          </v-card>
                          <v-textarea
                            class="ml-3"
                            key="edit-description-enabled"
                            v-else
                            solo
                            hint="The description for your badge"
                            v-model="badge.description"
                            label="Markdown Description"
                            :rules="descriptionRules"
                            required
                            persistent-hint
                          />
                        </transition>
                      </v-col>
                      <v-col cols="12" md="12">
                        <span class="text-h6 pa-2">Criteria:</span>
                        <transition name="slide-y-transition" mode="out-in">
                          <v-card flat v-if="!editModeEnabled" key="edit-description" class="ml-3">
                            <v-card-text>
                              <div>
                                <p v-html="compiledMarkdown(badge.criteria)"></p>
                              </div>
                            </v-card-text>
                          </v-card>
                          <v-textarea
                            class="ml-3"
                            key="edit-description-enabled"
                            v-else
                            solo
                            hint="The criteria used to issue this badge"
                            v-model="badge.criteria"
                            label="Markdown Criteria"
                            :rules="descriptionRules"
                            required
                            persistent-hint
                          />
                        </transition>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="justify-center">
            <v-col cols="12" md="2" class="ma-4">
              <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
                style="margin-top:150px"
              ></v-progress-circular>
            </v-col>
          </v-row>
          </div>
        </v-tab-item>
        <v-tab-item class="no-transition"  color="#f5f5f5">
          <div class="tab-item-wrapper">
            <badge-employee-list-component :badge="badge" :group="group" />
          </div>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>
    </v-form>
    <v-dialog
      v-model="showBigImageDialog"
      width="500"
    >
      <v-card v-if="badge">
        <v-card-title>{{ badge.displayName }}</v-card-title>
        <v-card-text class="d-flex align-center justify-center">
          <v-img
            max-height="300"
            max-width="300"
            min-height="300"
            min-width="300"
            :src="badge.image.base64Image"
            contain
            style="width: 300px"
          />
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showBigImageDialog = false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImageDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">Update Badge Image</v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12" md="9">
                <vue-cropper
                  v-if="badge && badge.image && badge.image.base64Image"
                  ref="cropper"
                  :aspect-ratio="5 / 5"
                  :src="badge.image.base64Image"
                  :movable="true"
                  :scalable="true"
                  :cropBoxMovable="true"
                  :cropBoxResizable="false"
                  :autoCrop="true"
                  style="max-width: 300px; max-height: 300px;"
                />
                <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn color="warning" icon @click="reset"><v-icon>mdi-file-undo</v-icon></v-btn>
                <v-btn icon @click="zoom(0.2)"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="zoom(-0.2)"><v-icon>mdi-minus</v-icon></v-btn>
                <v-btn color="primary" icon @click="$refs.FileInput.click()"><v-icon>mdi-cloud-upload</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row v-if="badge && badge.image">
              <v-col>
                <v-text-field solo v-model="badge.image.caption" label="Alt Text" hint="alt text for screen readers" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showImageDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            :loading="isSubmittingImage"
            :disabled="isSubmittingImage"
            @click="updateImage"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import marked from 'marked'
import badgeEmployeeListComponent from './components/badgeEmployeeListComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'BadgeDetails',
  components: {
    'badge-employee-list-component': badgeEmployeeListComponent,
    VueCropper
  },
  computed: {
    ...mapGetters([
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined || (this.group && this.group.users.find(x => x.userId.toLowerCase() === this.badgeUser.userId.toLowerCase()) !== undefined)
      }
    }
  },
  data: () => ({
    editModeEnabled: false,
    tabView: 0,
    selectedFile: null,
    showImageDialog: false,
    showBigImageDialog: false,
    badgeName: null,
    badge: null,
    group: null,
    groupId: null,
    isLoading: false,
    isSubmittingImage: false,
    isSubmitted: false,
    isDeleting: false,
    validated: true,
    required: [
      v => !!v || 'This field is required',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 2000) || 'must be less than 500 characters'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ]
  }),
  methods: {
    convertBase64ToFile: function () {
      let arr = this.badge.image.base64Image.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let uint8Array = new Uint8Array(n)
      while (n--) {
        uint8Array[n] = bstr.charCodeAt(n)
      }
      let file = new File([uint8Array], this.badge.displayName, { type: mime })
      return file
    },
    downloadBadgeImage: function () {
      const url = window.URL.createObjectURL(new Blob([this.convertBase64ToFile()]))
      const link = document.createElement('a')
      link.href = url
      if (this.badge.image.contentType === 'image/png') {
        link.setAttribute('download', `${this.badge.displayName}.png`)
      } else if (this.badge.image.contentType === 'image/svg') {
        link.setAttribute('download', `${this.badge.displayName}.svg`)
      }
      document.body.appendChild(link)
      link.click()
    },
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    onFileSelect: function (e) {
      const file = e.target.files[0]
      this.badge.image.contentType = file.type
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom: function (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    reset: function () {
      this.$refs.cropper.reset()
    },
    showUpdateImageDialog: function () {
      this.showImageDialog = true
    },
    getBadge: function () {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${this.badgeName}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.badge = response.data
            this.groupId = this.badge.groupId
            this.getImage(this.badge)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getBadge() })
          })
    },
    getGroup: function () {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.groupId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.group = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getGroup() })
          })
    },
    displayDeleteDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to delete this Badge? This action can not be undone. Users who had this badge issued will not be removed',
        confirmMethod: () => {
          this.deleteBadge()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    deleteBadge: function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${this.badge.id}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.$router.push(`/badges/groups/${this.groupName}`)
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.deleteBadge() })
          })
    },
    validate: function () {
      this.validated = true

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    updateBadge: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      let badgeUpdate = this.badge
      badgeUpdate.image.base64Image = null

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${this.group.id}/badges/${this.badge.id}`,
        {
          method: 'PUT',
          data: JSON.stringify(badgeUpdate),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.getBadge().then(() => {
              this.editModeEnabled = false
              this.isSubmitted = false
              this.$store.commit('triggerSnack', { snackMessage: 'The badge has been updated successfully', snackColor: 'success' })
            })
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateBadge() })
            return error
          })
    },
    getImage: function (badge) {
      if (badge.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${badge.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.badge.image = response.data
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    },
    updateImage: function () {
      let croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.isSubmittingImage = true

      this.badge.image.base64Image = croppedImage

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${this.badge.id}/image`,
        {
          method: 'POST',
          data: JSON.stringify(this.badge.image),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isSubmittingImage = false
            this.$store.commit('triggerSnack', { snackMessage: 'The image has been updated successfully', snackColor: 'success' })
            this.getBadge().then(() => {
              this.showImageDialog = false
            })
          },
          (error) => {
            this.isSubmittingImage = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateImage() })
            return error
          })
    }
  },
  mounted: function () {
    if (this.$route.params.badgeName) {
      this.groupName = this.$route.params.groupName
      this.badgeName = this.$route.params.badgeName
      this.getBadge().then(() => {
        this.getGroup().then(() => {
          this.$store.commit('breadcrumbs', [
            {
              text: 'Groups',
              exact: true,
              disabled: false,
              to: '/badges/groups'
            },
            {
              text: this.group.displayName,
              exact: true,
              disabled: false,
              to: `/badges/groups/${this.group.name}`
            },
            {
              text: 'Badges',
              exact: true,
              disabled: true
            },
            {
              text: this.badge.displayName,
              exact: true,
              disabled: false,
              to: `/badges/details/${this.badge.name}`
            }
          ])
        })
      })
    } else {
      alert('could not find badge as a group name and badge name was not provided in the url')
    }
  }
}
</script>

<style scoped>
.group-name {
  padding-top: 35px;
}

.group-name-loading {
  padding-top: 15px;
}

.loading-name {
  width: 150px;
}

.edit-component {
  padding-top: 15px;
}

.no-transition {
  transition: none;
}

.image-select {
  cursor: pointer;
}

.tab-item-wrapper {
  /* vuetify sets the v-tabs__container height to 48px */
  min-height: calc(100vh - 220px)
}

</style>
