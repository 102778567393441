var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Employee Badges "),_c('v-spacer'),(_vm.hasAdminRole)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showIssueBadgeDialog = true}}},[_vm._v("Issue Badge")]):_vm._e()],1),_c('v-card-subtitle',[_vm._v(" The following list of employees have been issued the "+_vm._s(_vm.badge.displayName)+" Badge. You can issue this badge to a new employee using the API or the button on the right ")]),_c('v-card-text',[_c('v-divider'),_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"11","md":"7"}},[_c('v-text-field',{attrs:{"label":"Search for assertions by user id","placeholder":"","prepend-inner-icon":"mdi-magnify","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.hasAdminRole)?_c('v-col',{staticClass:"ml-0 pl-0",staticStyle:{"margin-top":"2px"},attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-menu',{attrs:{"offset-x":"","nudge-right":"5","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":"","color":_vm.selectedSearchOptions.length == 0 ? 'gray' : 'info'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tools")])],1)]}}],null,false,3183613896)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_vm._v(" Search Options: "),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.searchOptions,"item-text":"value","item-value":"key","menu-props":{ maxHeight: '400' },"label":"Options","clearable":"","multiple":""},model:{value:(_vm.selectedSearchOptions),callback:function ($$v) {_vm.selectedSearchOptions=$$v},expression:"selectedSearchOptions"}})],1)],1)],1)],1):_vm._e(),(_vm.hasAdminRole)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{key:"multi-select-action",staticStyle:{"margin-top":"2px"},attrs:{"disabled":_vm.isDeleting || _vm.selectedAssertions.length == 0,"loading":_vm.isDeleting,"color":"error","text":"","x-large":""},on:{"click":_vm.displayRevokeDialog}},[_vm._v("Revoke Selected")])],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"disable-sort":"","items-per-page":_vm.limit,"disabled":_vm.isDeleting,"headers":_vm.headers,"items":_vm.assertions,"options":_vm.options,"server-items-length":_vm.count,"loading":_vm.isLoading,"item-key":"id","show-select":_vm.hasAdminRole},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ("+_vm._s(item.user.userId)+")")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.isRevoked)?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(" Revoked ")]):_vm._e(),(item.status === null && !item.isRevoked)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" Pending ")]):_vm._e(),(item.status === 0 && !item.isRevoked)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" Accepted ")]):_vm._e(),(item.status === 1 && !item.isRevoked)?_c('v-chip',{attrs:{"color":"warning","small":""}},[_vm._v(" Declined ")]):_vm._e()]}},{key:"item.issuedDateTimeUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalDate(item.issuedDateTimeUtc))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":'/badges/assertions/' + item.id,"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.selectedAssertions),callback:function ($$v) {_vm.selectedAssertions=$$v},expression:"selectedAssertions"}})],1)],1)],1)],1)],1)],1)],1),_c('issue-badge-component',{attrs:{"badge":_vm.badge,"showDialog":_vm.showIssueBadgeDialog},on:{"updateAssertions":_vm.getAssertions,"dialogEvent":_vm.handleDialogEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }