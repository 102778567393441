<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title>
            Employee Badges
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showIssueBadgeDialog = true" v-if="hasAdminRole">Issue Badge</v-btn>
          </v-card-title>
          <v-card-subtitle>
            The following list of employees have been issued the {{ badge.displayName }} Badge. You can issue this badge to a new employee using the API or the button on the right
          </v-card-subtitle>
          <v-card-text>
            <v-divider />
            <v-container>
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="11" md="7">
                  <v-text-field
                      label="Search for assertions by user id"
                      placeholder=""
                      prepend-inner-icon="mdi-magnify"
                      outlined
                      clearable
                      v-model="search"
                    >
                    </v-text-field>
                </v-col>
                <v-col v-if="hasAdminRole" cols="12" sm="1" md="1" class="ml-0 pl-0" style="margin-top:2px;">
                <v-menu
                  offset-x
                  nudge-right="5"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      x-large
                      v-bind="attrs"
                      v-on="on"
                      :color="selectedSearchOptions.length == 0 ? 'gray' : 'info'"
                    >
                      <v-icon>mdi-tools</v-icon>
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-text>
                      Search Options:
                      <v-select
                        style="max-width: 300px"
                        v-model="selectedSearchOptions"
                        :items="searchOptions"
                        item-text="value"
                        item-value="key"
                        :menu-props="{ maxHeight: '400' }"
                        label="Options"
                        clearable
                        multiple
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
                <v-col cols="12" md="2" v-if="hasAdminRole">
                  <v-btn :disabled="isDeleting || selectedAssertions.length == 0" :loading="isDeleting" @click="displayRevokeDialog" color="error" text x-large style="margin-top: 2px;" key="multi-select-action">Revoke Selected</v-btn>
                </v-col>
              </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      disable-sort
                      :items-per-page="limit"
                      :disabled="isDeleting"
                      v-model="selectedAssertions"
                      :headers="headers"
                      :items="assertions"
                      :options.sync="options"
                      :server-items-length="count"
                      :loading="isLoading"
                      item-key="id"
                      :show-select="hasAdminRole"
                    >
                      <template v-slot:item.user="{ item }">
                        <span>{{ item.user.firstName }} {{ item.user.lastName}} ({{ item.user.userId }})</span>
                      </template>
                        <template v-slot:item.status="{ item }">
                        <v-chip v-if="item.isRevoked" small color="error">
                          Revoked
                        </v-chip>
                        <v-chip v-if="item.status === null && !item.isRevoked" small>
                          Pending
                        </v-chip>
                        <v-chip v-if="item.status === 0 && !item.isRevoked" color="success" small>
                          Accepted
                        </v-chip>
                        <v-chip v-if="item.status === 1 && !item.isRevoked" color="warning" small>
                          Declined
                        </v-chip>
                      </template>
                      <template v-slot:item.issuedDateTimeUtc="{ item }">
                        {{ getLocalDate(item.issuedDateTimeUtc) }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn :to="'/badges/assertions/' + item.id" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
                      </template>
                    </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <issue-badge-component @updateAssertions="getAssertions" :badge="badge" :showDialog="showIssueBadgeDialog" @dialogEvent="handleDialogEvent" />
  </v-container>
</template>

<script>
import issueBadgeComponent from './issueBadgeComponent'
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'BadgesUserComponent',
  props: {
    badge: {
      required: true
    },
    group: {
      required: true
    }
  },
  components: {
    'issue-badge-component': issueBadgeComponent
  },
  computed: {
    ...mapGetters([
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined || (this.group && this.group.users.find(x => x.userId.toLowerCase() === this.badgeUser.userId.toLowerCase()) !== undefined)
      }
    }
  },
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getAssertions()
      }, 500)
    },
    options: {
      handler () {
        this.page = this.options.page
        this.getAssertions()
      },
      deep: true
    },
    selectedSearchOptions: function () {
      this.getAssertions()
    }
  },
  data: () => ({
    selectedSearchOptions: [ 'hideRevoked', 'hideDeclined' ],
    searchOptions: [
      {
        key: 'hideRevoked',
        value: 'Hide Revoked Assertions'
      },
      {
        key: 'hidePending',
        value: 'Hide Pending Assertions'
      },
      {
        key: 'hideExpired',
        value: 'Hide Expired Assertions'
      },
      {
        key: 'hideDeclined',
        value: 'Hide Declined Assertions'
      }
    ],
    selectedAssertions: [ ],
    showIssueBadgeDialog: false,
    assertions: [ ],
    searchTimeout: null,
    search: '',
    isLoading: false,
    isDeleting: false,
    page: 1,
    limit: 15,
    count: 0,
    options: {},
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Accepted/Declined',
        align: 'start',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Issued',
        align: 'start',
        sortable: false,
        value: 'issuedDateTimeUtc'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ]
  }),
  methods: {
    displayRevokeDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to revoke the selected assertions? <br/><br/>This action can not be undone and will result in notifications to users about the revocation. <br/><br/>Revocation will stop any further updates on the selected assertion and new assertions will need to be created to re-issue this badge to affected employees.',
        confirmMethod: () => {
          this.revokeSelectedAssertions()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    revokeSelectedAssertions: async function () {
      this.isDeleting = true

      for (let index = 0; index < this.selectedAssertions.length; index++) {
        // Make the request
        await this.revokeAssertion(this.selectedAssertions[index].id)
      }

      this.getAssertions()
      this.isDeleting = false
      this.selectedAssertions = [ ]
    },
    revokeAssertion: async function (id) {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions/${id}/revoke`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            return true
          },
          () => {
            return false
          })
    },
    handleDialogEvent: function () {
      this.showIssueBadgeDialog = false
    },
    getAssertions: function () {
      this.isLoading = true
      let searchOptions = this.selectedSearchOptions.join(',')
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${this.badge.id}/assertions?page=${this.page}&limit=${this.limit}&search=${this.search}&options=${searchOptions}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.assertions = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getBadges() })
          })
    },
    getLocalDate: function (date) {
      return moment.utc(date).local().format('lll')
    },
    routeToAssertion: function (id) {
      this.$router.push(`/badges/assertions/${id}`)
    }
  },
  beforeMount: function () {
    this.getAssertions()
  }
}
</script>

<style scoped>
/* .v-window__container {
    height: 100% !important;
  } */
</style>
