<template>
  <div>
    <v-dialog
      v-model="showIssueBadgeDialog"
      width="1000"
    >
      <v-card>
        <v-card-title>
          Issue {{ badge.displayName }} Badge
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="badgeCreatorForm">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="assertion.evidence"
                        label="Evidence"
                        hint="The supporting information as to why this badge is being issued to the users"
                        persistent-hint
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu
                        ref="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="assertion.expirationDateTimeUtc"
                            label="Badge Exiration Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hint="A date in the future when this assertion should expire. Use this if certifications that this badge represents expire"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="assertion.expirationDateTimeUtc"
                          no-title
                          scrollable
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col cols="12" md="12">
                      <user-search-component :error="userError" class="required" :disabled="isSubmitted" v-model="currentUserSearch" label="Users to issue badge to" />
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">34</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="user in users" :key="user.Key">
                                <td>{{ user.Username }}</td>
                                <td>{{ user.DisplayName }}</td>
                                <v-btn icon @click="deleteUser(user.Username)"><v-icon>mdi-delete</v-icon></v-btn>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col class="d-flex justify-center">
            <span v-if="validationError" style="color: #ff5252 !important; font-size: 10pt !important;">There was an error with your request. Please check for errors above and resolve them before trying again</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showIssueBadgeDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            :loading="isSubmitted"
            :disabled="isSubmitted"
            @click="issueBadge"
          >
            Issue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showErrorDialog" width="500">
      <v-card>
        <v-card-title>
          Errors during request
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span>There were some errors while processing your request for the following users. Please try creating assertions for each user separately to find more information</span>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          User Id
                        </th>
                        <th class="text-left">
                          User Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in errors"
                        :key="index"
                      >
                        <td>{{ item.user.userId }}</td>
                        <td>{{ item.user.firstName }} {{item.user.lastName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import userSearchComponent from '@/components/userSearchComponent'
import moment from 'moment'

export default {
  name: 'IssueBadgeComponent',
  props: {
    badge: {
      required: true
    },
    showDialog: {
      required: true
    }
  },
  components: {
    'user-search-component': userSearchComponent
  },
  computed: {
    showIssueBadgeDialog: {
      get: function () {
        return this.showDialog
      },
      set: function () {
        this.$emit('dialogEvent', this.showDialog)
        return false
      }
    }
  },
  watch: {
    currentUserSearch: function (newValue) {
      if (newValue) {
        if (!this.users.find(x => x.Key === newValue.Key)) {
          this.users.push({
            Username: newValue.Key,
            DisplayName: newValue.Value
          })
        }
      }
    }
  },
  data: () => ({
    showErrorDialog: false,
    menu: false,
    errors: [ ],
    assertion: { },
    users: [ ],
    userError: false,
    isSubmitted: false,
    validationError: false,
    required: [
      v => !!v || 'This field is required',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'must be greater than 100 characters and less than 500 characters'
    ],
    currentUserSearch: ''
  }),
  methods: {
    validate: function () {
      let validated = true
      this.validationError = false
      if (!this.$refs.badgeCreatorForm.validate()) {
        validated = false
        this.validationError = true
      }

      return validated
    },
    issueBadge: async function () {
      // Set submitted flag and reset errors
      this.isSubmitted = true
      this.errors = [ ]

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      // Create a local copy of the assertion
      let assertion = JSON.parse(JSON.stringify(this.assertion))
      let userCount = this.users.length

      // Add the date time to the datetime for the start of the selected day
      // This will ensure our UTC time is correct
      if (this.assertion.expirationDateTimeUtc) {
        let startDateTime = moment(new Date(this.assertion.expirationDateTimeUtc)).startOf('day')
        assertion.expirationDateTimeUtc = startDateTime.toISOString(true)
      }

      // Set the additional assertion details
      assertion.groupId = this.badge.groupId
      assertion.badgeId = this.badge.id

      for (let index = 0; index < this.users.length; index++) {
        // Get user details
        var userDetails = await this.getUserDetails(this.users[index].Username)

        // Build the user item
        assertion.user = {
          userId: userDetails.Username,
          firstName: userDetails.FirstName,
          lastName: userDetails.LastName,
          email: userDetails.Email
        }

        // Make the request
        await this.createAssertion(assertion, userCount)
      }

      this.isSubmitted = false
      this.showIssueBadgeDialog = false

      if (this.errors.length > 0) {
        this.showErrorDialog = true
      }

      this.assertion = { }
      this.users = [ ]
      this.$emit('updateAssertions')
    },
    createAssertion: async function (assertion, userCount) {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions`,
        {
          method: 'POST',
          data: JSON.stringify(assertion),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            if (userCount === 1) {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createAssertion(assertion, userCount) })
            } else {
              this.errors.push(assertion)
            }
            return error
          })
    },
    getUserDetails: async function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    deleteUser: function (username) {
      this.users = this.users.filter((item) => {
        return item.Username !== username
      })
    }
  },
  beforeMount: function () {
  }
}
</script>

<style scoped>

</style>
